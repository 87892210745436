import React, { useState, useEffect } from "react"
import { useInterval, useEffectOnce } from "react-use"
import { Link } from "gatsby"
import { useWindowSize } from "@react-hook/window-size"
import random from "lodash.random"
import classnames from "classnames"
import { useStaticQuery, graphql } from "gatsby"

/* Import Local Styles */
import "./upcoming-projects.css"

/* Import Local Assets */
import UpcomingProjectsPortalSrc from "./assets/images/upcoming-projects-portal.jpg"

const UpcomingProjects = () => {
  const {
    allSanityProject: { totalCount },
  } = useStaticQuery(graphql`
    query UpcomingProjectsQuery {
      allSanityProject(filter: { upcomingProject: { eq: true } }) {
        totalCount
      }
    }
  `)

  const animationLength = 10000
  const [windowWidth, windowHeight] = useWindowSize()
  const [isVisible, setIsVisible] = useState(false)
  const [position, setPosition] = useState()

  useInterval(() => {
    setPosition({
      x: random(50, windowWidth - 300),
      y: random(100, windowHeight - 100),
    })
  }, animationLength)

  // initialize position
  useEffectOnce(() => {
    setPosition({
      x: random(50, windowWidth - 300),
      y: random(100, windowHeight - 100),
    })
  })

  // set portal visible whenever it moves
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.setTimeout(() => {
        setIsVisible(true)
      }, 1)
    }
  }, [position])

  // fade portal out 2 seconds before it moves
  useEffect(() => {
    if (isVisible) {
      if (typeof window !== "undefined") {
        window.setTimeout(() => {
          setIsVisible(false)
        }, animationLength - 2000)
      }
    }
  }, [isVisible])

  if (!position || totalCount === 0) {
    return null
  }

  return (
    <Link
      to={`/upcoming-projects`}
      className={classnames("upcoming-projects-portal", {
        isVisible,
      })}
      style={{ top: position.y, left: position.x }}
    >
      <img
        src={UpcomingProjectsPortalSrc}
        alt="A circular puddle reflecting clouds"
      />
    </Link>
  )
}

export default UpcomingProjects
